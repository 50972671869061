<template>
  <div class="booklist"
  >
    <BookItem
        v-for="book in props.books.slice(0,booksLength)" :key="book.gtin13"
        :book="book"
        :cta="true"
        :shouldHideNonFavorite="props.shouldHideNonFavorite"
    ></BookItem>

    <div
        class="button-group button-group--center button-group--wide button-group--vertical"
        v-if="allBooksOrFavorites.length>booksLength">
      <a class="button" @click="more">Meer</a>
    </div>
    <p class="booklist__notfound" v-else-if="showHelpMessage">Boek niet gevonden? Probeer de zoekopdracht te verfijnen.</p>
  </div>
</template>
<script setup>
import BookItem from './BookItem'
import {computed, defineProps,defineEmits, ref} from "vue";
import {useStore} from "vuex";

const store = useStore()
const emit = defineEmits(['more'])
const props = defineProps({
  books: {
    type: Array
  },
  length:{
    type:Number,
    default:10
  },
  shouldHideNonFavorite: {
    type: Boolean,
    default: false,
  },
  showHelpMessage:{
    type:Boolean,
    default:false,
  }
})
const isInFavorites = (book) => store.getters.favorites.findIndex(favorite => favorite.gtin13 === book.gtin13) >= 0

const allBooksOrFavorites = computed(() => {
  if (props.shouldHideNonFavorite) {
    return props.books.filter(book => isInFavorites(book))
  }
  return props.books
})

const booksLength = ref(props.length)

const more = () => {
  emit('more')
  booksLength.value = booksLength.value + 10;
}
</script>
