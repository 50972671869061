<template>
  <Loading v-if="loading"/>
  <Appbar :background="false" :fixed="true" :back="true"/>
  <div class="scanner-wrapper" :class="{'scanner-wrapper--loading':loading}">
    <p v-if="error">Oeps de camera kon niet geladen worden.</p>
    <div id="reader" class="reader" style="width:100%"
         :class="{'reader--loading':loading}"></div>
    <label class="scanner__button button" for="file">Gebruik een foto</label>
    <input class="scanner__upload" name="file" type="file" id="file" ref="file"
           accept="image/*"
           @change="handleFileUpload" hidden/>
  </div>
</template>
<script setup>
import {defineEmits, onBeforeUnmount, onMounted, ref, watch} from 'vue'
import Loading from "./Loading";
import Appbar from "./Appbar";
import * as Sentry from "@sentry/vue";

const code = ref()
const file = ref()
const qrScanner = ref();
const devices = ref([]);
const loading = ref(true);
const error = ref(false)
onMounted(() => {
  load()
})
const load = () => {
  // eslint-disable-next-line no-undef
  qrScanner.value = new Html5Qrcode("reader");
  // eslint-disable-next-line no-undef
  Html5Qrcode.getCameras().then(devs => {
    const config =
        {
          fps: 10,
          rememberLastUsedCamera: true,
          qrbox: {width: 250, height: 250}
        }
    devices.value = [...devs]

    // eslint-disable-next-line no-undef
    qrScanner.value.start(
        {
          facingMode: {exact: "environment"}
        },
        config,
        foundCode,
        () => loading.value = false
    ).catch(() => {
      qrScanner.value.start(
          devices.value[0].id,
          config,
          foundCode,
          () => loading.value = false
      ).catch(err => {
        console.error(err)
        error.value = true
        loading.value = false
      })
    })

  }).catch((err) => {
    console.error(err)
    Sentry.captureException(err)
    error.value = true
    loading.value = false
  })
}

const emit = defineEmits(['success','notFound'])
watch(code, () => {
  if (code.value) {
    emit('success', code.value)
  }
})

onBeforeUnmount(() => {
  qrScanner.value.stop()
  qrScanner.value = null;
})
const foundCode = (text) => {
  emit('success', text)
}
const notFound = () => {
  emit('notFound')
}
const handleFileUpload = () => {
  loading.value = true
  const [image] = file.value.files
  qrScanner.value.stop()
  qrScanner.value.scanFile(image).then(foundCode).catch(notFound)
}

</script>