<template>
  <Snackbar/>
  <router-view v-if="!loading"/>
</template>
<script setup>
import Snackbar from './components/Snackbar'
import {ref} from 'vue'
import {useStore} from "vuex"

const store = useStore()
const loading = ref(false)
if (store.getters.userCode) {
  loading.value = true
  if (!store.getters.user.firstName) {
    store.dispatch('getAndSetUser')
    loading.value=false
  }
} else {
  loading.value = false
}
</script>