<template>
  <Appbar :fixed="true" title="Boekenhandels"/>
  <div class="view-wrapper view-wrapper--appbar">
    <div class="search-wrapper">
      <input class="search-input" type="text" placeholder="Postcode of gemeente"
             :value="initialInput" @input="e => { input = e.target.value; initialInput = e.target.value}"
             name="" id="">
      <a class="search-cross icon-cross" @click="emptySearch"></a>
    </div>
    <div class="view-content view-content--flex view-content--center" v-if="loading">
      <Spinner/>
    </div>
    <div class="main" v-else>
      <StoreList v-if="result && result.length>0" :stores="result"/>
      <div v-else class="search-description view-content view-content--center">
        <p v-if="input.length<2">Typ bovenaan om te zoeken naar boekenhandels.</p>
        <p v-else>Geen resultaten gevonden.</p>
      </div>
    </div>
  </div>
</template>
<script setup>
import Appbar from "../components/Appbar";
import Spinner from "../components/Spinner";

import {useDebouncedRef} from "../helpers/shared";
import {searchStore} from "../helpers/api";
import {ref, watch} from "vue";
import StoreList from "../components/StoreList";
import * as Sentry from "@sentry/vue";


const input = useDebouncedRef('', 300)
const result = ref()
const loading = ref(false)
const initialInput = ref('')
const emptySearch = () => {
  initialInput.value = '';
  input.value = '';
}

watch(input, (value) => {
  if (value.length >= 2) {
    loading.value = true
    searchStore(value).then(res => {
      console.log(res)
      loading.value = false
      const {data} = res;
      if (data.data && data.code === 200) {
        result.value = data.data
      }
    }).catch(err => {
      loading.value=false;
      Sentry.captureException(err)
    })
  } else {
    result.value = {}
  }
})
</script>