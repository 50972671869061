import axios from 'axios'

export const instance = axios.create({
    baseURL: '/nl/api',
    headers: {
        "content-type": "application/json"
    }
})
const validate = '/ticket'
const search = '/books/search'
const favorites = '/books/get-favorites'
const addFav = '/books/add-favorite'
const removeFav = '/books/delete-favorite'
const storeSearch = '/bookstore/search'
const account = '/account'
const bookCode = '/books/get'

export async function validateTicket(ticket) {
    try {
        const response = await instance.get(`${validate}/${ticket}`)
        if(response.status===200){
            return response.data
        }else{
            return null
        }
    } catch (err) {
        return null
    }
}

export async function searchBook(term) {
    return instance.get(`${search}/${term}`)
}
export async function getBook(code){
    return instance.get(`${bookCode}/${code}`)
}
async function getSecure(url, user) {
    return instance.get(url, {headers: {'Auth-Ticket-Id': user}})
}

async function postSecure(url) {
    return instance.post(url, {})
}

export async function getFavorites() {
    return getSecure(favorites)
}

export async function addFavorite(barcode) {
    const term = `${addFav}/${barcode}`
    return postSecure(term)
}

export async function removeFavorite(barcode) {
    const term = `${removeFav}/${barcode}`
    return postSecure(term)
}

export async function searchStore(search) {
    return instance.get(`${storeSearch}/${search}`)
}

export async function createAccount(data){
    return instance.post(`${account}/save`, data)
}

export async function getAccount(){
    return instance.get(account)
}