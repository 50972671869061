<template>
  <div class="view">
    <Appbar :back="true" title="Ticket koppelen"/>
    <div class="view-wrapper">
      <div class="view-content view-content--wide view-content--center">
        <p class="ticket-description">
          Om jouw persoonlijke boekenlijst bij te
          houden vragen we jouw persoonlijke Lees!-toegangsticket te koppelen.
          Zo kan je op een nieuw toestel later altijd jouw eigen boeken-lijst
          terug ophalen.
        </p>

        <div class="input-group">
          <label for="ticket" class="input-label">Barcode van je
            Lees!-ticket</label>
          <input placeholder="9946659265621"
                 class="input-input input-input--large"
                 type="text" id="ticket" name="ticket" v-model="ticket">
        </div>
        <p class="ticket-help">
          De barcode van je Lees!-ticket vind je terug op het PDF-ticket of de
          Wallet-kaart
          die je bij de reservatie van jouw ticket ontving.
        </p>
        <div class="ticket-button button-group">
          <a @click="submit" class="ticket-button__button button button--wide">Ticket
            koppelen</a>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
import {ref} from 'vue'
import {validateTicket} from "../../helpers/api";
import {useStore} from "vuex";
import {useRouter} from "vue-router";
import Appbar from "../../components/Appbar";

const ticket = ref('')
const store = useStore()
const router = useRouter()

const submit = () => {
  validateTicket(ticket.value).then(res => {
    if (!res) {
      store.dispatch('setSnack', {
        message: 'Sorry, er werd geen valide ticket gevonden.',
        type: 'warning'
      })
      return
    }
    const {data} = res;
    if (data && data.email.length > 0) {
      store.dispatch('userLogIn', data)
      store.dispatch('addQueue')
      router.push('/')
    } else if (data) {
      const {ticketId} = data
      store.dispatch('setUserCode', ticketId)
      router.push({name: 'CreateAccount'})
    }
  }).catch(console.warn)
}
</script>