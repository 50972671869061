<template>
  <div class="view-content view-content--center">
    <div class="booklist-image">
      <img src="@/assets/img/logo.png" alt="" class="home__image">
    </div>
    <p class="booklist-description">
      Maak jouw persoonlijke lijst met boeken die je wilt ontdekken op
      Lees!
      of scan en bewaar boeken tijdens jouw bezoek.
    </p>
    <div class="button-group button-group--vertical">
      <router-link to="/scan/book" class="button">Scan een boek
      </router-link>
      <router-link to="/search" class="button">Zoek een boek</router-link>
      <router-link to="/account/login" class="cta-small" v-if="!user.email">Ik heb al een Lees!-boekenlijst</router-link>
    </div>
  </div>
</template>
<script setup>
import {computed} from 'vue'
import {useStore} from "vuex";
const store = useStore()
const user = computed( () => store.getters.user)

</script>