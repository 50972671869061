<template>
    <QRScanner @success="success" @notFound="notFound" v-if="render"></QRScanner>
</template>
<script setup>
import QRScanner from "../../components/QRScanner"
import {ref, nextTick} from 'vue'
import {useRouter} from 'vue-router'
import {useStore} from "vuex";
import {getBook} from "../../helpers/api";

const store = useStore()
const router = useRouter()
const render = ref(true)
// const goBack = () => router.go(-1)
const success = (code) => {
  if (code) {
    getBook(code).then( result => {
      const {data} = result;
      if ( ! data.data ){
        store.dispatch('setSnack', {
          message:'Boek niet gevonden.. Probeer een ander boek.',
          type:"warning"
        })
        router.replace('/scan/book')
        forceRender()

      }else{
        store.dispatch('setScanResult', data.data)
        router.push({name: 'ScanResult', params: {code: code}, replace: true})
      }
    }).catch(err=>{
      console.log(err)
      store.dispatch('setSnack', {
        message:'Geen valide code gevonden.. Probeer opnieuw.',
        type:"warning"
      })
      forceRender()
      router.replace('/scan/book')

    })
  }
}
const notFound = () => {
  store.dispatch('setSnack', {
    message:'Geen code gevonden.. Probeer opnieuw.',
    type:"warning"
  })
  forceRender()
  router.replace('/scan/book')
}
const forceRender = () => {
  render.value = false;
  nextTick( () => render.value = true)
}
</script>
