<template>
  <QRScanner @success="success" @notFound="notFound"  v-if="!loading && render"></QRScanner>
  <Loading v-if="loading"/>
</template>
<script setup>
import QRScanner from "../../components/QRScanner";
import {useStore} from "vuex";
import {validateTicket} from '../../helpers/api'
import {useRouter} from "vue-router";
import {ref, nextTick} from 'vue';
import Loading from "../../components/Loading";

const router = useRouter();
const store = useStore()
const loading = ref(false);
const render = ref(true)

const success = (data) => {
  loading.value = true
  validateTicket(data).then(res => {
    if (!res) {
      store.dispatch('setSnack', {
        message: 'Sorry, er werd geen valide ticket gevonden.',
        type: 'warning'
      })
      loading.value = false
      forceRender()
      return
    }
    const {data} = res;
    if (data && data.email.length > 0 && data.dateComplete) {
      store.dispatch('userLogIn', data)
      store.dispatch('getAndSetFavoriteBooks').then(()=>{
        router.push('/')

        store.dispatch('addQueue')
        loading.value=false;
      })

    } else if (data) {
      const {ticketId} = data
      store.dispatch('setUserCode', ticketId)
      if(data.email.length>3) store.dispatch('setUser', data)
      router.push({name: 'CreateAccount'})
      loading.value = false
    }
  }).catch(err => {
    console.error(err)
    store.dispatch('setSnack', {
      message: 'Sorry, er werd geen valide ticket gevonden.',
      type: 'warning'
    })
    loading.value = false
    forceRender()
  })
}
const notFound = () => {
  store.dispatch('setSnack', {
    message:'Geen ticket gevonden.. Probeer opnieuw.',
    type:"warning"
  })
  forceRender()
}
const forceRender = () => {
  render.value = false;
  nextTick( () => render.value = true)
}

</script>
