<template>
  <div class="appbar" :class="{'appbar--fixed':fixed, 'appbar--background':background}">
    <a v-if="props.back" @click="goBack" class="cta-back icon-arrow-left2" :class="{'cta-back--background':!background}"></a>
    <h1 class="view-title">{{props.title}}</h1>
    <Settings v-if="props.settings"/>
  </div>
</template>
<script setup>
import Settings from './Settings'
import {defineProps} from 'vue'
import {useRouter} from 'vue-router'

const router = useRouter()
const props = defineProps({
  title: {
    type:String,
    default:"",
  },
  back: {
    type: Boolean,
    default: false,
  },
  settings: {
    type: Boolean,
    default: false
  },
  backRoute:{
    type:String,
    default:''
  },
  fixed:{
    type:Boolean,
    default:false,
  },
  background:{
    type:Boolean,
    default:true,
  }
})
const goBack = () => {
  if(props.backRoute.length>0){
    router.replace(props.backRoute)
    return null;
  }
  router.go(-1)

}
</script>