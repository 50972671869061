<template>
  <div class="floating-wrapper">
      <ul class="floating-menu" v-if="floatingMenuActive">
          <li class="floating-menu__item"  v-for="item in menuItems" :key="item.id">
            <router-link :to="item.route" class="item__wrapper floating-menu__link">
              <span class="floating-menu__label">
                {{item.label}}
            </span>
              <a class="floating-menu__icon" :class="item.icon"></a>
            </router-link>
          </li>
      </ul>
    <div class="cta-floating-wrapper" :class="{'cta-floating-wrapper--active':floatingMenuActive}" @click="floating">
      <div class="cta-floating icon-plus"></div>
    </div>
  </div>
</template>
<script setup>
import {onBeforeUnmount, onMounted, ref} from "vue";

const floatingMenuActive = ref(false)
const menuItems = ref([
  {
    id:1,
    icon:'icon-barcode',
    label:"Scan een boek",
    route:'/scan/book'
  },
  {
    id:2,
    icon:'icon-search',
    label:"Zoek een boek",
    route:'/search'
  },
])
const floating = () => {
  floatingMenuActive.value = !floatingMenuActive.value
}
const setFloatingInActive = (e) => {
  if (e.target.classList) {
    const item = Array.from(e.target.classList).find(item => item.includes('floating'))
    if (item) return;
  }
  floatingMenuActive.value = false
}
onMounted(() => {
  document.addEventListener('click', setFloatingInActive);
  document.addEventListener('scroll', setFloatingInActive)
})
onBeforeUnmount(() => {
  document.removeEventListener('click', setFloatingInActive)
  document.removeEventListener('scroll', setFloatingInActive)
})
</script>