<template>
  <div class="view-wrapper">

    <BookList :shouldHideNonFavorite="true" :books="favorites"></BookList>
  </div>
  <FloatingActionMenu/>
</template>
<script setup>
import BookList from '../components/BookList.vue'
import {useStore} from "vuex";
import {computed} from 'vue';
import FloatingActionMenu from "./FloatingActionMenu";

const store = useStore()
const favorites = computed(() => store.getters.favorites)
</script>