<template>
  <Appbar title="Mijn profiel" :back="true"/>
  <div class="view-wrapper view-wrapper--full">
    <div class="settings">
      <ul class="info-list">
        <li class="info__item">
          <div class="item-group">
            <p>Ticket</p>
            <p>{{user.ticketId}}</p>
          </div>
        </li>
        <li class="info__item">
          <div class="item-group">
            <p>Voornaam</p>
            <p>{{user.firstName}}</p>
          </div>
        </li>
        <li class="info__item">
          <div class="item-group">
            <p>Achternaam</p>
            <p>{{user.surname}}</p>
          </div>
        </li>
        <li class="info__item">
          <div class="item-group">
            <p>Email</p>
            <p>{{user.email}}</p>
          </div>
        </li>
      </ul>
      <div class="view-content view-content--flex" >

      <div class="button-group button-group--center settings-buttons">
        <a v-if="userCode" @click="logOut" class="settings__text cta-secondary">Afmelden</a>
      </div>
    </div>
      </div>
  </div>
</template>
<script setup>
import Appbar from '../../components/Appbar'
import {computed} from 'vue'
import {useStore} from "vuex";
import {useRouter} from 'vue-router'

const router = useRouter()
const store = useStore()
const userCode = computed(() => store.getters.userCode)
const user = computed( () => store.getters.user)
const logOut = () => {
  store.dispatch('logOut')
  router.push('/')
}


</script>