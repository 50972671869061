import {createRouter, createWebHashHistory} from 'vue-router'
import Home from '../views/Home.vue'
import SearchResult from '../views/Book/SearchResult'
import Stores from '../views/Stores'
import BookScanner from '../views/Scanner/BookScanner'
import TicketScanner from '../views/Scanner/TicketScanner'
import TicketInput from '../views/Scanner/TicketInput'
import ScanResult from '../views/Book/ScanResult'
import BookDetail from '../views/Book/BookDetail'
import AccountLogIn from '../views/User/AccountLogIn'
import SettingsView from '../views/User/SettingsView'
import CreateAccount from '../views/User/CreateAccount'

const routes = [
    {
        path: '/',
        name: 'Home',
        component: Home
    },
    {
        path: '/result/:code',
        name: "ScanResult",
        component: ScanResult,
    },
    {
        path: '/detail/:book',
        name: 'BookDetail',
        component: BookDetail,
    },
    {
        path: '/search',
        name: 'Search',
        component: SearchResult
    },
    {
        path: '/stores',
        name: 'Stores',
        component: Stores
    },
    {
        path: '/scan/book',
        name: 'BookScanner',
        component: BookScanner
    },
    {
        path: '/scan/ticket',
        name: 'TicketScanner',
        component: TicketScanner
    },
    {
        path: '/ticket/input',
        name: 'TicketInput',
        component: TicketInput
    },
    {
        path: '/account/login',
        name: 'LogIn',
        component: AccountLogIn
    },
    {
        path: '/settings',
        name: 'Settings',
        component: SettingsView
    },
    {
        path: '/account/create',
        name: 'CreateAccount',
        component: CreateAccount
    },


]

const router = createRouter({
    history: createWebHashHistory(),
    routes,
    scrollBehavior(to, from, savedPosition) {
        if (savedPosition) {
            return savedPosition
        } else if (to.hash) {
            return {
                selector: to.hash,
            }
        } else {
            return {top: 0}
        }
    },
})

export default router
