<template>
  <template v-if="!loading">
    <Appbar :settings="!!userCode" title="Mijn boekenlijst"/>
    <component :is="favorites.length>0 && user.dateComplete ?BookListView:BookCallToActionView"/>
  </template>
  <Loading v-else/>
</template>

<script setup>
import Appbar from '../components/Appbar'
import {useStore} from "vuex";
import {computed, ref} from 'vue';
import BookCallToActionView from "./BookCallToActionView";
import BookListView from "./BookListView";
import Loading from "../components/Loading";
import * as Sentry from "@sentry/vue";

const loading = ref(false);

const store = useStore()
const userCode = computed(() => store.getters.userCode)
const user = computed(() => store.getters.user)
const favorites = computed(() => store.getters.favorites)
if (userCode.value && !user.value.email) {
  loading.value=true
  store.dispatch('tryAndGetUser').then(() => {
    loading.value = false
  }).catch((err) => {
    Sentry.captureException(err)
    loading.value = false
  })
}
if(user.value.email && favorites.value.length<0){
  store.dispatch('getAndSetFavoriteBooks')
}
</script>