import store from '../store'
import router from '../router'
import {customRef} from "vue";

export function checkIfUser() {
    return store.getters.user.email && store.getters.user.email.length > 0
}

export async function reRoute(to = '/') {
    if (checkIfUser()) {
        router.push(to)
    } else {
        store.dispatch('getAndSetUser').then(() => {
            if (checkIfUser()) router.push(to)
        })
    }
}

export function useDebouncedRef(value, delay = 2000) {
    let timeout
    return customRef((track, trigger) => {
        return {
            get() {
                track()
                return value
            },
            set(newValue) {
                clearTimeout(timeout)
                timeout = setTimeout(() => {
                    value = newValue
                    trigger()
                }, delay)
            }
        }
    })
}

export function useTimedRef(value,empty="", delay = 2000) {
    let timeout
    return customRef((track, trigger) => {
        return {
            get() {
                track()
                return value
            },
            set(newValue) {
                value = newValue
                trigger()
                clearTimeout(timeout)
                timeout = setTimeout(() => {
                    value = empty
                    trigger()
                }, delay)
            }
        }
    })
}

export function isValidEmail(email) {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
}

