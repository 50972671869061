<template>
  <div class="view" v-if="book.title">
    <div class="hero"
         :style="{ backgroundImage: `url(${book.frontCoverThumbnailUrl||image})` }">
      <Appbar :background="false" :back="true"/>
    </div>
    <div class="view-wrapper">
      <div class="detail__content">
        <div class="detail__header">
          <div class="header__wrapper">
            <div class="header__title-author">
              <h1 class="detail__title">{{ book.title }}</h1>
              <h2 class="detail__subtitle">{{ book.subtitle }}</h2>
              <h3 class="detail__author">{{ authorString }}</h3>
            </div>
            <div class="header__favorite">
              <a class="cta-medium icon-cross cta-medium--orange cta-medium--small"
                 v-if="isFavorite" @click="removeFromFavorite"></a>
              <a class="cta-medium icon-heart cta-medium--small" @click="addToFavorite" v-else></a>
            </div>
          </div>
        </div>

          <h3 class="detail__zone">{{book.themaZone}}</h3>
        <div class="detail__more">
          <div class="detail__info" v-if="book.partTitle">
            <p class="detail__info__label">Deel</p>
            <p class="detail__info__content">{{ book.partTitle }}</p>
          </div>
          <div class="detail__info" v-if="book.originalTitle">
            <p class="detail__info__label">Originele titel</p>
            <p class="detail__info__content">{{ book.originalTitle }}</p>
          </div>
          <div class="detail__info" v-if="mainSubjects && mainSubjects.length>0">
            <p class="detail__info__label">Hoofdonderwerp</p>
            <p class="detail__info__content">{{ mainSubjectsString }}</p>
          </div>
          <div class="detail__info"
               v-if="otherSubjects && otherSubjects.length>0">
            <p class="detail__info__label">Andere onderwerpen</p>
            <p class="detail__info__content">{{ otherSubjectsString }}</p>
          </div>

          <div class="detail__info"
               v-if="book.languages && book.languages.length===1">
            <p class="detail__info__label">Taal</p>
            <p class="detail__info__content">{{ book.languages[0].label }}</p>
          </div>
          <div class="detail__info" v-else>
            <p class="detail__info__label">Talen</p>
            <ul class="detail__info__content detail__info__content--list">
              <li v-for="language in book.languages" :key="language.code">
                {{ language.label }}
              </li>
            </ul>
          </div>
          <div class="detail__info">
            <p class="detail__info__label">Uitgever</p>
            <p class="detail__info__content">{{ book.publisher }}</p>
          </div>
          <div class="detail__info">
            <p class="detail__info__label">ISBN</p>
            <p class="detail__info__content">{{ book.isbnFirstPublished }}
              ({{ book.productForm.shortLabel }})</p>
          </div>
        </div>
        <div class="detail__buttons button-group button-group--vertical"
             id="#details">
          <a class="button" @click="removeFromFavorite" v-if="isFavorite">Verwijder
            uit mijn lijst</a>
          <a class="button" @click="addToFavorite" v-else>Voeg toe aan mijn
            lijst</a>
          <router-link to="/scan/book" class="button button--outline">Scan een
            ander boek
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
import * as Sentry from "@sentry/vue";
import {useRoute, useRouter} from "vue-router";
import {computed, onMounted, ref} from 'vue'
import {useStore} from "vuex";
import {useDebouncedRef} from "../../helpers/shared";
import Appbar from "../../components/Appbar";
import {getBook} from "../../helpers/api";

const store = useStore()
const book = computed(() => store.getters.bookForDetail)
const route = useRoute()
const router = useRouter()
const loading = ref(false)
let image = '';
if (!book.value.frontCoverThumbnailUrl) {
  image = require('@/assets/img/placeholder.png')
}

if (!book.value || book.value.gtin13 !== route.params.book) {
  loading.value = true;
  getBook(route.params.book).then(response => {
    const {data} = response;
    if (data.code === 200) {
      store.dispatch('setBookForDetail', data.data)
      loading.value = false
    } else {
      store.dispatch('setSnack', {
        message: 'Oeps.. Er liep iets fout.',
        type: 'warning'
      })
      router.replace('/')
    }
  }).catch((err) => {
    console.error(err)
    Sentry.captureException(err)
    store.dispatch('setSnack', {
      message: 'Oeps.. Er liep iets fout.',
      type: 'warning'
    })
    router.replace('/')
  })
}
// Authors
const authors = computed(() => {
  return book.value.contributors ? book.value.contributors.filter(contributor => contributor.role.code === 'A01') : []
})
const authorString = computed(() => {
  if (authors.value) {

    return authors.value.map(author => author.name ? author.name : author.corporateName).join(', ') || book.value.publisher || book.value.imprint
  }
  return ''
})


//Subject
const mainSubjects = computed(() => book.value.themas ? book.value.themas.filter(thema => thema.isMainSubject) : [])
const mainSubjectsString = computed(() => mainSubjects.value.map(thema => thema.label).join(', '))
const otherSubjects = computed(() => book.value.themas ? book.value.themas.filter(thema => !thema.isMainSubject) : [])
const otherSubjectsString = computed(() => otherSubjects.value.map(thema => thema.label).join(', '))

const isInFavorites = () => store.getters.favorites.findIndex(favorite => favorite.gtin13 === book.value.gtin13) >= 0
const isInToRemove = () => store.getters.booksToRemove.findIndex(toRemove => toRemove.gtin13 === book.value.gtin13) >= 0

const isFavorite = ref(isInFavorites() && !isInToRemove())
const timeOut = ref(null)
const remove = useDebouncedRef(false)

const addToFavorite = () => {
  if (store.getters.userCode) {
    remove.value = false;
    isFavorite.value = true;
    if(timeOut.value){
      clearTimeout(timeOut.value)
      timeOut.value=null
      store.dispatch('setSnack', {})
      return;
    }
    store.dispatch('addToFavorite', book.value)
    router.push('/')
    return null;
  }
  store.dispatch('addToQueue', book.value)
  router.push('/account/login')
}
const removeFromFavorite = () => {
  if(timeOut.value) clearTimeout(timeOut.value)
  store.dispatch('pushBookToRemove', book.value)
  store.dispatch('setSnack', {
    message: `${book.value.title} is verwijderd van mijn boekenlijst.`,
    type: 'warning',
    func: () => {
      clearTimeout(timeOut.value)
      store.dispatch('popBookToRemove', book.value)
      timeOut.value = null
      isFavorite.value=true
    },
    funcTitle: 'Ongedaan maken',
  })

  timeOut.value = setTimeout(() => {
    store.dispatch('removeFromFavorite', book.value).then(success => {
    if (!success) {
      isFavorite.value = true
    }})
    timeOut.value = null
  },5000)
  router.push('/')
  isFavorite.value = false;
}
onMounted(async () => {
  window.scrollTo(0, document.body.scrollHeight);
})
</script>