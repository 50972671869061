import {createStore} from 'vuex'
import * as Sentry from "@sentry/vue";
import {VueCookieNext} from 'vue-cookie-next'
import {
    addFavorite,
    getAccount,
    getFavorites,
    instance,
    removeFavorite
} from "../helpers/api";

export default createStore({
    state: {
        userCode: VueCookieNext.getCookie('lees_user_code') || '',
        favorites: [],
        bookForDetail: {},
        snack: {
            message: "",
            type: "",
            time: 0,
            func: null,
            funcTitle: ''
        },
        snackTimeout: null,
        queue: [],
        user: {},
        searchTerm:'',
        searchResult:{},
        searchLength:10,
        scanResult:{},
        booksToRemove:[]
    },
    mutations: {
        SET_USER_CODE(state, code) {
            state.userCode = code
        },
        SET_BOOK_FOR_DETAIL(state, book) {
            state.bookForDetail = {...book}
        },
        GET_COOKIE(state) {
            state.cookie = VueCookieNext.getCookie('test_cookie')
        },
        ADD_TO_QUEUE(state, data) {
            state.queue.push(data)
        },
        SET_SNACK(state, snack) {
            state.snack = {...snack}
        },
        UNSET_SNACK(state) {
            state.snack = {}
        },
        SET_FAVORITES(state, books) {
            console.log(books)
            state.favorites = [...books]
        },
        ADD_TO_FAVORITES(state, book) {
            state.favorites.unshift(book)
        },
        REMOVE_FROM_FAVORITES(state, book) {
            state.favorites = [...state.favorites.filter(favorite => favorite.gtin13 !== book.gtin13)]
        },
        REMOVE_USER_COOKIE(state) {
            state.userCode = null;
            state.user = {};
            VueCookieNext.removeCookie('lees_user_code')
        },
        ADD_QUEUE_TO_FAVORITES(state) {
            state.favorites = [...state.queue, ...state.favorites]
        },
        SET_USER(state, data) {
            state.user = {...data}
        },
        SET_SEARCH_TERM(state, term){
            state.searchTerm = term
        },
        SET_SEARCH_RESULT(state, result){
            state.searchResult = result
        },
        SET_SEARCH_LENGTH(state, length){
            state.searchLength = length
        },
        SET_SCAN_RESULT(state, book){
            state.scanResult = book
        },
        EMPTY_FAVORITES(state){
            state.favorites = []
        },
        EMPTY_QUEUE(state){
            state.queue = []
        },
        PUSH_BOOK_TO_REMOVE(state, book){
            state.booksToRemove.push(book)
        },
        POP_BOOK_TO_REMOVE(state,book){
          state.booksToRemove = state.booksToRemove.filter(rem=>rem.gtin13!==book.gtin13)
        },
        SET_BOOKS_TO_REMOVE(state,books){
            state.booksToRemove = [...books]
        }
    },
    actions: {
        setUserCode({commit}, code) {
            commit('SET_USER_CODE', code)
            instance.defaults.headers.common['X-Auth-Ticket-Id'] = code
            VueCookieNext.setCookie('lees_user_code', code)
        },
        setBookForDetail({commit}, book) {
            commit("SET_BOOK_FOR_DETAIL", book)
        },
        async getAndSetFavoriteBooks({commit}) {
            try {
                const {data} = await getFavorites()
                const favorites = data.data
                commit('SET_FAVORITES', favorites)
                return data
            } catch (err) {
                console.error(err);
                return err
            }
        },
        saveCookie({commit}, code) {
            VueCookieNext.setCookie('test_cookie', code, {expire:'30d'})
            commit('GET_COOKIE')
        },
        // eslint-disable-next-line no-unused-vars
        addToFavorite({commit, dispatch, state}, book) {
            if(state.favorites.find(fav => fav.gtin13 === book.gtin13)){
                dispatch('setSnack', {
                    message: `${book.title} is toegevoegd aan uw boekenlijst!`,
                    type: 'info'
                })
                return null;
            }
            commit('ADD_TO_FAVORITES', book)
            addFavorite(book.gtin13).catch((err) => {
                Sentry.captureException(err)
                dispatch('setSnack', {
                    message: `Oeps... Er ging iets verkeerd. ${book.title} is niet toegevoegd aan uw boekenlijst.`,
                    type: 'warning'
                })
                commit('REMOVE_FROM_FAVORITES', book)
            })
            dispatch('setSnack', {
                message: `${book.title} is toegevoegd aan uw boekenlijst!`,
                type: 'info'
            })
        },
        userLogIn({dispatch}, data) {
            dispatch('setUserCode', data.ticketId)
            dispatch('setUser', data)
            dispatch('setSnack', {
                message: `Je bent succesvol aangemeld.`,
                type: 'info'
            })
        },
        addToQueue({commit}, data) {
            commit('ADD_TO_QUEUE', data)
        },
        setSnack({commit, dispatch, state}, snack) {
            commit('SET_SNACK', snack)
            if (state.snackTimeout) clearTimeout(state.snackTimeout)
            state.snackTimeout = setTimeout(() => {
                dispatch('unsetSnack');
            }, snack.time || 5000)
        },
        unsetSnack({commit}) {
            commit('UNSET_SNACK')
        },
        isInFavorites({state}, book) {
            return state.favorites.findIndex(favorite => favorite.gtin13 === book) >= 0
        },
        async removeFromFavorite({commit, dispatch}, book) {
            try {
                await removeFavorite(book.gtin13)
                commit('REMOVE_FROM_FAVORITES', book)
                commit('POP_BOOK_TO_REMOVE',book)
                return true
            } catch (err) {
                console.log(err)
                Sentry.captureException(err)
                commit('POP_BOOK_TO_REMOVE',book)
                dispatch('setSnack', {
                    message: 'Er liep iets mis.. Boek is niet verwijdert uit uw boekenlijst.',
                    type: 'warning',
                    time: 2000
                })
                return false
            }
        },
        logOut({commit}) {
            commit('EMPTY_FAVORITES')
            commit('EMPTY_QUEUE')
            commit('REMOVE_USER_COOKIE')
        },
        addQueue({commit, dispatch, state}) {
            state.queue.forEach(book => {
                addFavorite(book.gtin13).then(
                    dispatch('setSnack', {
                        message: `${book.title} toegevoegd aan uw boekenlijst!`,
                        type: `info`
                    })
                ).catch((err) => {
                    Sentry.captureException(err)
                    dispatch('setSnack', {
                        message: `Oeps.. Er liep iets mis. ${book.title} is niet toegevoegd aan uw boekenlijst.`,
                        type: 'warning'
                    })
                })
            })
            commit("ADD_QUEUE_TO_FAVORITES")
        },
        setUser({commit}, data) {
            commit('SET_USER', data)
        },
        async getAndSetUser({commit, dispatch}) {
            try {
                const res = await getAccount()
                if (res.data.data) {
                    commit('SET_USER', res.data.data)
                    return dispatch('getAndSetFavoriteBooks')

                } else {
                    commit('REMOVE_USER_COOKIE')
                    return null
                }
            } catch (err) {
                Sentry.captureException(err)
                console.log(err)
                return null
            }
        },
        async tryAndGetUser({dispatch, state}) {
            if (state.userCode) {
                return dispatch('getAndSetUser')
            }else{
                return null;
            }
        },
        saveSearchState({commit}, search){
            commit('SET_SEARCH_TERM', search.term)
            commit('SET_SEARCH_RESULT', search.result)
            commit('SET_SEARCH_LENGTH', search.length)
        },

        setScanResult({commit}, book){
            commit('SET_SCAN_RESULT', book)
        },

        setBooksToRemove({commit}, books){
            commit('SET_BOOKS_TO_REMOVE',books)
        },

        pushBookToRemove({commit}, book){
            commit('PUSH_BOOK_TO_REMOVE', book)
        },

        popBookToRemove({commit}, book){
            commit('POP_BOOK_TO_REMOVE', book)
        }

    },
    getters: {
        userCode: (state) => state.userCode,
        bookForDetail: (state) => state.bookForDetail,
        cookie: (state) => state.cookie,
        snack: (state) => state.snack,
        favorites: (state) => [...state.favorites],
        user: (state) => state.user,
        searchTerm: (state) => state.searchTerm,
        searchResult: (state)=> state.searchResult,
        searchLength: (state)=>state.searchLength,
        scanResult: (state) => state.scanResult,
        booksToRemove: (state) => state.booksToRemove,
    },
    modules: {}
})
