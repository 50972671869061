import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import { VueCookieNext } from 'vue-cookie-next'
import * as Sentry from "@sentry/vue";
import { Integrations } from "@sentry/tracing";

import "@/assets/style/main.scss"
const app = createApp(App)
Sentry.init({
    app,
    dsn: "https://dbc8969580014cd58a46a91964d15122@sentry.tigron.net/140",
    integrations: [
        new Integrations.BrowserTracing({
            routingInstrumentation: Sentry.vueRouterInstrumentation(router)
        })
    ],

    tracesSampleRate: 1.0
})
VueCookieNext.config({ expire: '30d' })
app.config.errorHandler = (err, vm, info) => console.error(err,vm,info)
app.use(store).use(router).use(VueCookieNext).mount('#app')
