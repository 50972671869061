<template>
  <div class="book book--simple" v-if="!(props.shouldHideNonFavorite && !isFavorite)">
    <img v-if="!props.book.frontCoverThumbnailUrl" @click="goToDetail" class="book__img" src="@/assets/img/placeholder.png"
         :alt="props.book.title" width="80" height="80">
    <img v-else @click="goToDetail" class="book__img" :src="props.book.frontCoverThumbnailUrl"
         :alt="props.book.title" width="80" height="80">
    <div @click="goToDetail" class="book__content">
      <p class="book__author">{{ authorString }}</p>
      <h1 class="book__title">{{ props.book.title }}</h1>
      <h2 class="book__subtitle" v-if="props.book.subtitle">{{props.book.subtitle}}</h2>
      <p class="book__description">{{ themasString}}</p>
    </div>
    <div class="book__button" v-if=props.cta>
      <div class="cta-thumb-wrapper"
           :class="{'cta-thumb-wrapper--pink':isFavorite}">
        <a class="cta-thumb icon-heart" @click="addToFavorite" v-if="!isFavorite"></a>
        <a class="cta-thumb icon-cross icon--small" @click="removeFromFavorite" v-else></a>
      </div>
    </div>
  </div>
</template>
<script>

</script>
<script setup>
import {defineProps, ref, computed, onBeforeMount, watchEffect} from "vue";
import {useDebouncedRef} from "../helpers/shared";

import {useRouter} from 'vue-router';
import {useStore} from "vuex";

const props = defineProps({
  book: Object,
  cta: {
    type: Boolean,
    default: true,
  },
  shouldHideNonFavorite:{
    type:Boolean,
    default:false
  }
})

// eslint-disable-next-line no-unused-vars
const truncate = (str, length) => {
  if (str.length > length) {
    str = str.substr(0, length)
    str += '...';
    return str
  }
  return str;
}
const router = useRouter()
const store = useStore()
const remove = useDebouncedRef(false)
const isFavorite = ref(false)
const timeOut = ref()
const booksToRemove = computed (()=>store.getters.booksToRemove)


const authors = computed ( () => {
  return props.book.contributors.filter(contributor => contributor.role.code ==='A01')
})

const authorString = computed( () => authors.value.map( author => author.name?author.name:author.corporateName).join(', ') || props.book.publisher || props.book.imprint)
const themasString = computed( () => props.book.themas.map(thema => thema.label).join(', '))

const isInFavorites = () => store.getters.favorites.findIndex(favorite => favorite.gtin13 === props.book.gtin13) >= 0
const isInToRemove = () => booksToRemove.value.findIndex(toRemove => toRemove.gtin13 === props.book.gtin13) >= 0
onBeforeMount(async () => {
  isFavorite.value = (isInFavorites() && !isInToRemove())
})

watchEffect(()=>{
  if (booksToRemove.value && !isInToRemove() && isInFavorites()) {
    isFavorite.value = true
  }
})

const goToDetail = () => {
  store.dispatch('setBookForDetail', {...props.book})
  router.push({name: 'BookDetail', params: {book: props.book.gtin13}, meta:{scrollIntoView:false}, hash:"#details"})
}


const addToFavorite = () => {
  if (store.getters.userCode) {
    if(timeOut.value){
      remove.value = false;
      isFavorite.value = true;
      store.dispatch('setSnack', {})
      clearTimeout(timeOut.value)
      timeOut.value = null
      return null;
    }
    store.dispatch('addToFavorite', props.book)
    isFavorite.value = true
    remove.value = false
    router.push('/')
    return null;
  }
  store.dispatch('addToQueue', props.book)
  router.push('/account/login')
}
const removeFromFavorite = () => {
  if(timeOut.value) clearTimeout(timeOut.value)
  store.dispatch('setSnack', {
    message: `${props.book.title} is verwijderd van mijn boekenlijst.`,
    type: 'warning',
    func: () => {
      clearTimeout(timeOut.value)
      timeOut.value = null
      isFavorite.value=true
    },
    funcTitle: 'Ongedaan maken',
  })
  timeOut.value = setTimeout(() => store.dispatch('removeFromFavorite', props.book).then(success => {
    if (!success) {
      isFavorite.value = true
    }
  }),5000)

  isFavorite.value = false;
}
</script>