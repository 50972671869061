<template>
  <Appbar :back="true" title="Maak je boekenlijst"/>
  <div class="view-wrapper">
    <div class="create view-content view-content--center view-content--wide ">

      <p class="create__description">
        U bent er bijna! We hebben volgende informatie nog van u nodig.
      </p>
      <div class="input-group">
        <label for="firstName" class="input-label">Voornaam</label>
        <input class="input-input" id="firstName" name="firstName" type="text"
               v-model="firstName"/>
      </div>
      <div class="input-group">
        <label class="input-label" for="surname">Naam</label>
        <input class="input-input" id="surname" name="surname" type="text"
               v-model="surname"/>
      </div>
      <div class="input-group">
        <label class="input-label" for="email">Email</label>
        <input class="input-input" id="email" name="email" type="email"
               v-model="email"/>
      </div>
      <div class="input-check">
        <input type="checkbox" class="optin_check" v-model="optin">
        <label class="check__text create__disclaimer">Ja, hou me verder op de hoogte per e-mail met uitnodigingen en interessante inhoud van Easyfairs?</label>
      </div>
      <div class="button-group button-group--vertical button-group--full-width">
        <a @click="submit" class="button create__button">Ga verder</a>
      </div>
    </div>
  </div>
</template>
<script setup>
import * as Sentry from "@sentry/vue";
import {useRouter} from "vue-router";
import {computed, ref} from 'vue'
import {createAccount} from "../../helpers/api";
import {useStore} from "vuex";
import {isValidEmail} from "../../helpers/shared";
import Appbar from "../../components/Appbar";

const router = useRouter()
const store = useStore()
const user = computed(()=>store.getters.user)
const firstName = ref(user.value.firstName||'')
const surname = ref(user.value.surname||'')
const email = ref(user.value.email||'')
const optin = ref(false)

const submit = () => {
  if (firstName.value.length > 1 && surname.value.length > 1 && email.value.length > 3) {
    if (isValidEmail(email.value)) {
      createAccount({
        firstName: firstName.value,
        surname: surname.value,
        email: email.value,
        optin: optin.value
      }).then(res => {
        if (res.data) {
          store.dispatch('setUser', res.data)
          store.dispatch('addQueue')
          router.push('/')
        }
      }).catch(err => {
        Sentry.captureException(err)
      })
    } else {
      store.dispatch('setSnack', {
        message: 'Emailadres is niet valide.',
        type: 'warning'
      })
    }

  } else {
    store.dispatch('setSnack', {
      message: 'Kijk na of alle velden goed ingevuld zijn.',
      type: 'warning'
    })
  }
}
</script>