<template>
  <Appbar title="Scanresultaat" backRoute="/scan/book" :back="true"/>
  <div class="view-wrapper">
    <p class="result__code">{{ code }}</p>
    <div class="view-content view-content--extra-wide" >
        <BookList v-if="book.gtin13" :books="[book]"/>
      <div class="button-group button-group--center button-group--wide button-group--vertical result__button">
        <router-link to="/scan/book"
                     class="button button--outline">
          Scan een ander
          boek
        </router-link>
        <router-link v-if="user" to="/"
                     class="button">
          Mijn boekenlijst
        </router-link>
      </div>
    </div>
  </div>
</template>
<script setup>
import BookList from '../../components/BookList'
import Appbar from '../../components/Appbar'
import {ref, computed} from "vue";
import {useStore} from 'vuex'
import {useRoute} from 'vue-router';
import {getBook} from "../../helpers/api";
import * as Sentry from "@sentry/vue";

const route = useRoute()
const code = ref(route.params.code)
const store = useStore()

const user = computed (()=>store.getters.userCode)
const book = computed(()=>store.getters.scanResult)
if(!book.value || !book.value.gtin13 || book.value.gtin13 !== route.params.code){
  getBook(route.params.code).then(response=>{
    const {data} = response;
    if(!data || !data.data){
      store.dispatch('setSnack', {
        message:'Oeps.. er liep iets mis.',
        type:'warning'
      })
    }else{
      store.dispatch('setScanResult', data.data)
    }
  }).catch(err => {
    Sentry.captureException(err)
  })
}

</script>