<template>
  <transition name="slide-fade">
    <div class="snackbar" v-if="snack.message"
         :class="{'snackbar--warning':snack.type==='warning',
       'snackbar--info':snack.type==='info',
       }"
    @click="close">
      <p class="snackbar__text">{{ snack.message }}</p>
      <a class="snackbar__function" v-if="snack.func" @click="snack.func">{{snack.funcTitle}}</a>
    </div>
  </transition>
</template>
<script setup>
import {useStore} from "vuex";
import {computed} from "vue";

const store = useStore()
const snack = computed(() => store.getters.snack)
const close = () => {
  store.dispatch('unsetSnack')
}
</script>