<template>
  <Appbar :fixed="true" :back="showBack" title="Zoek een boek"/>
  <div class="view-wrapper view-wrapper--appbar">
    <div class="search-wrapper">
      <input class="search-input" type="text" placeholder="Zoeken"
             :value="initialInput" @input="e => { input = e.target.value; initialInput = e.target.value}"
             name="" id="">
      <a class="search-cross icon-cross" @click="emptySearch"></a>
    </div>
    <div class="view-content--flex view-content--center" v-if="loading">
      <Spinner/>
    </div>
    <div class="view-content view-content--extra-wide view-content--flex" v-else>
      <BookList v-if="result.data && result.data.length>0" @more="more" :length="searchLength" :books="result.data" :showHelpMessage="true"/>
      <div v-else class="search-description view-content--center">
        <p v-if="input.length<3">Typ bovenaan om te zoeken naar boeken.</p>
        <p v-else>Geen resultaten gevonden.</p>
      </div>
    </div>
  </div>

</template>
<script setup>
import {watch, ref} from 'vue'
import BookList from "../../components/BookList";
import Appbar from "../../components/Appbar";
import Spinner from "../../components/Spinner";
import {useDebouncedRef} from "../../helpers/shared";
import {searchBook} from "../../helpers/api";
import {useStore} from "vuex";
import * as Sentry from "@sentry/vue";


const store = useStore()
const result = ref(store.getters.searchResult)
const input = useDebouncedRef(store.getters.searchTerm,300)
const initialInput = ref(store.getters.searchTerm)
const searchLength = ref(store.getters.searchLength)
const loading = ref(false)
const showBack = ref(window.history.length>1)
const more = () => {
  searchLength.value = searchLength.value +10;
}
const emptySearch = () => {
  initialInput.value = '';
  input.value = '';
}
watch( input, (value)=>{
  searchLength.value=10;

  if(value.length>=3){
    loading.value=true
    searchBook(value).then(response=>{
      const {data} = response;
      if(data.code===200){
        result.value =data.data
        store.dispatch('saveSearchState', {term: input, result: data.data, length:searchLength})

      }
      loading.value=false
    }).catch(err => {
        loading.value=false;
         Sentry.captureException(err)
    })

  }else{
    result.value = {}
  }
})

</script>